<template>
    <div>
        <div class="userinfo">
            <img class="userinfo-avatar" :src="userimg" />
            <div>{{usernick}}</div>
        </div>
        <div class="card_bg">
            <div class="subcard_bg" @click="goMember">
                <div class="vip_info">{{vipinfo}}</div>
                <div class="open_member" >{{ifmemberinfo}}</div>
            </div>
        </div>
        <div class="rowslip"></div>
        <van-grid style="padding: 10px; background-color: #fff;" :border="false">
            <van-grid-item icon="https://sqpro.oss-cn-beijing.aliyuncs.com/ecomm/icon/%E5%BE%85%E4%BB%98%E6%AC%BE.png" text="待付款" @touchstart="showdfk" @click="showdfk"/>
            <van-grid-item icon="https://sqpro.oss-cn-beijing.aliyuncs.com/ecomm/icon/%E5%BE%85%E5%8F%91%E8%B4%A7.png" text="待确认" @touchstart="showdqr" @click="showdqr"/>
            <van-grid-item icon="https://sqpro.oss-cn-beijing.aliyuncs.com/ecomm/icon/%E5%BE%85%E8%AF%84%E4%BB%B7.png" text="待评价" @touchstart="showdpj" @click="showdpj"/>
            <van-grid-item icon="https://sqpro.oss-cn-beijing.aliyuncs.com/ecomm/icon/%E5%AE%8C%E6%88%90.png" text="已完成" @touchstart="showywc" @click="showywc"/>
        </van-grid>
        <van-cell icon="https://sqpro.oss-cn-beijing.aliyuncs.com/ecomm/icon/%E6%B8%85%E7%A9%BA.png" title="分享链接" is-link @click="shareshop" />
        <van-cell icon="https://sqpro.oss-cn-beijing.aliyuncs.com/ecomm/icon/%E6%B8%85%E7%A9%BA.png" title="清空缓存" is-link @click="clearhc" />
        <van-cell icon="https://sqpro.oss-cn-beijing.aliyuncs.com/ecomm/icon/%E7%94%9F%E6%88%90%E4%BA%8C%E7%BB%B4%E7%A0%81.png" title="生成二维码" is-link @click="makecode" />
        <van-cell icon="https://sqpro.oss-cn-beijing.aliyuncs.com/ecomm/icon/%E8%81%94%E7%B3%BB%E6%88%91%E4%BB%AC.png" title="联系我们" is-link @click="contact" />
        <div class="rowslip" style="margin-bottom: 60px;"></div>
    </div>
</template>
<script>
    import Vue from 'vue'
    import '../../../static/css/my.css'
    import {Encrypt} from '../../../static/js/cryptoinfo.js'
    import '../../../static/font/iconfont.css'
    import axios from 'axios'
    import { Lazyload } from 'vant'
    import { Notify } from 'vant';
    Vue.use(Notify);
    Vue.use(Lazyload)
    Vue.prototype.$http = axios
    var that
    export default {
        name: 'My',
        data () {
            return {
                userinfo: '',
                userimg: '',
                usernick: '',
                ztdata: [],
                dfkcount: 0,
                ygmcount: 0,
                dfkdata: [],
                ygmdata:[],
                ifmemberinfo: '开通会员',
                vipinfo: 'VIP'
            }
        },
        mounted: function () {
            that = this
            // 获取UserInfo
            that.getuserinfo()
            if (localStorage.getItem('userimg') === null || localStorage.getItem('userimg') === '') {
                that.$options.methods.getuserinfo()
            } else {
                that.userimg = localStorage.getItem('userimg')
                that.usernick = localStorage.getItem('nickname')
                // that.$options.methods.reguserinfo()
                that.$options.methods.getdfkorder()
                that.$options.methods.getdpjorder()
                that.$options.methods.getdqrorder()
                that.$options.methods.getywcorder()
            }
            stopTouchendPropagationAfterScroll()
        },
        methods: {
            showtcard () {
                var qstr = 'userid = ' + localStorage.getItem('userid')
                window.console.log(qstr)
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/order/getbtci',
                    method: 'post',
                    data: {
                        whereinfo: {String: qstr, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            if (res.data.data !== '[]') {
                                var obj1 = JSON.parse(res.data.data)
                                localStorage.setItem('jhtcard',JSON.stringify(obj1))
                                that.$router.push({ path: '/TCardMana' })
                            } else {
                                that.$router.push({ path: '/TCardInfo' })
                            }
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            goMember () {
                if ((localStorage.getItem('userinfo') === null || localStorage.getItem('userinfo') === '')) {
                    // 表示压根就没有获取到用户信息，需要注册
                    localStorage.setItem('inpath','My')
                    that.$router.push({ path: '/RegMember' })
                } else {
                    // 存在用户信息，这是只需要补填会员信息就OK了
                    if (that.vipinfo === "VIP") {
                        var rmdStr = that.RondomPass(10)
                        var adddata = {
                            userid: parseInt(localStorage.getItem('userid')),
                            buycount: parseInt(0),
                            levelid: parseInt(1),
                            cardid: parseInt(1),
                            cardno: { String: rmdStr, Valid: true },
                            cusid: parseInt(localStorage.getItem('cusid'))
                        }
                        that.$http({
                            url: 'https://ecommapi.sqnetsoft.cn/cardticket/aaubmc',
                            method: 'post',
                            data: adddata,
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })
                            .then(function (res) {
                                if (res.data.success) {
                                    if (localStorage.getItem('inpath') === 'My') {
                                        that.$router.push({ path: '/My' })
                                    }
                                }
                            })
                            .catch(function (error) {
                                window.console.log('error' + error)
                            })
                    }
                }
            },
            RondomPass: function (number) {
                var arr = []
                var arr1 = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
                for(var i = 0; i<number; i++) {
                    var n = Math.floor(Math.random() * 10)
                    arr[i] = arr1[n]
                }
                var valinfo = arr.join('')
                return valinfo
            },
            getmemberdata () {
                var qmemberstr = 'userid = ' + localStorage.getItem('userid')
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/cardticket/getbmc',
                    method: 'post',
                    data: {
                        whereinfo: {String: qmemberstr, Valid: true}
                    },
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        window.console.log('会员数据')

                        if (res.data.success) {
                            if (res.data.data !== '[]') {
                                var obj1 = JSON.parse(res.data.data)
                                window.console.log(obj1)
                                that.vipinfo = "VIP" + obj1[0].level
                                that.ifmemberinfo = "No: " + obj1[0].cardno.String
                            } else {
                                window.console.log("[]")
                                that.vipinfo = "VIP"
                                that.ifmemberinfo = "成为会员"
                            }
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getdqrorder () {
                var qstr2 = {
                    whereinfo: " ownphone='" + localStorage.getItem('username') + "' and state_id <= 3 and state_id > 1",
                    limit: '',
                    sortinfo: 'order by op_time desc '
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/queryorderinfo',
                    method: 'post',
                    data: qstr2,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            var tmpdata = res.data
                            window.console.log('订单信息')
                            that.dfkdata = tmpdata
                            that.dfkcount = tmpdata.length
                            window.console.log(that.dfkdata)
                            var orderarr = []
                            for (var i = 0; i < tmpdata.length; i++) {
                                var objtmp = tmpdata[i]
                                orderarr.push('"' + objtmp.order_no + '"')
                            }
                            var qstr3 = {
                                whereinfo: 'order_no in (' + orderarr + ')',
                                limit: '',
                                sortinfo: ''
                            }
                            that.$http({
                                url: 'https://ecommapi.sqnetsoft.cn/queryorderdetail',
                                method: 'post',
                                data: qstr3,
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            })
                                .then(function (res) {
                                    if (res.data.length > 0) {
                                        var obj1 = res.data
                                        var dfktmp = that.dfkdata
                                        for (var j = 0; j < dfktmp.length; j++) {
                                            var dfkobj = dfktmp[j]
                                            var subordertmp = []
                                            for (var k = 0; k < obj1.length; k++) {
                                                if (dfkobj.order_no === obj1[k].order_no) {
                                                    window.console.log(obj1[k].order_no)
                                                    subordertmp.push(obj1[k])
                                                }
                                            }
                                            dfktmp[j].suborder = subordertmp
                                        }
                                        window.console.log(dfktmp)
                                        localStorage.setItem('dqrdata',JSON.stringify(dfktmp))
                                        that.dqrdata = JSON.parse(localStorage.getItem('dqrdata'))
                                    } else {
                                        window.console.log('获取商品失败')
                                    }
                                })
                                .catch(function (error) {
                                    window.console.log(error)
                                })
                            // 把相关数据缓存到本地
                        } else {
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getdpjorder () {
                var qstr2 = {
                    whereinfo: " ownphone='" + localStorage.getItem('username') + "' and state_id = 4",
                    limit: '',
                    sortinfo: 'order by op_time desc '
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/queryorderinfo',
                    method: 'post',
                    data: qstr2,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            if (res.data.data !== '[]') {
                                var tmpdata = res.data
                                window.console.log('订单信息')
                                that.dpjdata = tmpdata
                                that.dpjcount = tmpdata.length
                                window.console.log(that.dpjdata)
                                var orderarr = []
                                for (var i = 0; i < tmpdata.length; i++) {
                                    var objtmp = tmpdata[i]
                                    orderarr.push('"' + objtmp.order_no + '"')
                                }
                                var qstr3 = {
                                    whereinfo: 'order_no in (' + orderarr + ')',
                                    limit: '',
                                    sortinfo: ''
                                }
                                that.$http({
                                    url: 'https://ecommapi.sqnetsoft.cn/queryorderdetail',
                                    method: 'post',
                                    data: qstr3,
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                })
                                    .then(function (res) {
                                        if (res.data.length > 0) {
                                            var obj1 = res.data
                                            var dpjtmp = that.dpjdata
                                            for (var j = 0; j < dpjtmp.length; j++) {
                                                var dpjobj = dpjtmp[j]
                                                var subordertmp = []
                                                for (var k = 0; k < obj1.length; k++) {
                                                    if (dpjobj.order_no === obj1[k].order_no) {
                                                        window.console.log(obj1[k].order_no)
                                                        subordertmp.push(obj1[k])
                                                    }
                                                }
                                                dpjtmp[j].suborder = subordertmp
                                            }
                                            window.console.log(dpjtmp)
                                            localStorage.setItem('dpjorder',JSON.stringify(dpjtmp))
                                            that.dpjdata = JSON.parse(localStorage.getItem('dpjorder'))
                                        } else {
                                            that.dpjdata = []
                                            window.console.log('获取商品失败')
                                        }
                                    })
                                    .catch(function (error) {
                                        that.dpjdata = []
                                        window.console.log(error)
                                    })
                            }
                            // 把相关数据缓存到本地
                        } else {
                            that.dpjdata = []
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getdfkorder () {
                var qstr2 = {
                    whereinfo: " ownphone='" + localStorage.getItem('username') + "' and state_id = 1",
                    limit: '',
                    sortinfo: 'order by op_time desc '
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/queryorderinfo',
                    method: 'post',
                    data: qstr2,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            var tmpdata = res.data
                            window.console.log('订单信息')
                            that.dfkdata = tmpdata
                            that.dfkcount = tmpdata.length
                            window.console.log(that.dfkdata)
                            var orderarr = []
                            for (var i = 0; i < tmpdata.length; i++) {
                                var objtmp = tmpdata[i]
                                orderarr.push('"' + objtmp.order_no + '"')
                            }
                            var qstr3 = {
                                whereinfo: 'order_no in (' + orderarr + ')',
                                limit: '',
                                sortinfo: ''
                            }
                            that.$http({
                                url: 'https://ecommapi.sqnetsoft.cn/queryorderdetail',
                                method: 'post',
                                data: qstr3,
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            })
                                .then(function (res) {
                                    if (res.data.length > 0) {
                                        var obj1 = res.data
                                        var dfktmp = that.dfkdata
                                        for (var j = 0; j < dfktmp.length; j++) {
                                            var dfkobj = dfktmp[j]
                                            var subordertmp = []
                                            for (var k = 0; k < obj1.length; k++) {
                                                if (dfkobj.order_no === obj1[k].order_no) {
                                                    window.console.log(obj1[k].order_no)
                                                    subordertmp.push(obj1[k])
                                                }
                                            }
                                            dfktmp[j].suborder = subordertmp
                                        }
                                        window.console.log(dfktmp)
                                        localStorage.setItem('dfkorder',JSON.stringify(dfktmp))
                                        that.dfkdata = JSON.parse(localStorage.getItem('dfkorder'))
                                    } else {
                                        that.dfkdata = []
                                        window.console.log('获取商品失败')
                                    }
                                })
                                .catch(function (error) {
                                    that.dfkdata = []
                                    window.console.log(error)
                                })
                            // 把相关数据缓存到本地
                        } else {
                            that.dfkdata = []
                            localStorage.setItem('dfkorder',JSON.stringify('[]'))
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getywcorder () {
                var qstr2 = {
                    whereinfo: " ownphone='" + localStorage.getItem('username') + "' and state_id = 5",
                    limit: '',
                    sortinfo: 'order by op_time desc '
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/queryorderinfo',
                    method: 'post',
                    data: qstr2,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            if (res.data.data !== '[]') {
                                var tmpdata = res.data
                                window.console.log('订单信息')
                                that.ywcdata = tmpdata
                                that.ywccount = tmpdata.length
                                window.console.log(that.ywcdata)
                                var orderarr = []
                                for (var i = 0; i < tmpdata.length; i++) {
                                    var objtmp = tmpdata[i]
                                    orderarr.push('"' + objtmp.order_no + '"')
                                }
                                var qstr3 = {
                                    whereinfo: 'order_no in (' + orderarr + ')',
                                    limit: '',
                                    sortinfo: ''
                                }
                                that.$http({
                                    url: 'https://ecommapi.sqnetsoft.cn/queryorderdetail',
                                    method: 'post',
                                    data: qstr3,
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                })
                                    .then(function (res) {
                                        if (res.data.length > 0) {
                                            var obj1 = res.data
                                            var dpjtmp = that.ywcdata
                                            for (var j = 0; j < dpjtmp.length; j++) {
                                                var dpjobj = dpjtmp[j]
                                                var subordertmp = []
                                                for (var k = 0; k < obj1.length; k++) {
                                                    if (dpjobj.order_no === obj1[k].order_no) {
                                                        window.console.log(obj1[k].order_no)
                                                        subordertmp.push(obj1[k])
                                                    }
                                                }
                                                dpjtmp[j].suborder = subordertmp
                                            }
                                            window.console.log(dpjtmp)
                                            localStorage.setItem('ywcorder',JSON.stringify(dpjtmp))
                                            that.ywcdata = JSON.parse(localStorage.getItem('dpjorder'))
                                        } else {
                                            that.ywcdata = []
                                            window.console.log('获取商品失败')
                                        }
                                    })
                                    .catch(function (error) {
                                        that.ywcdata = []
                                        window.console.log(error)
                                    })
                            }
                            // 把相关数据缓存到本地
                        } else {
                            that.ywcdata = []
                            window.console.log('获取商品失败')
                        }
                    })
                    .catch(function (error) {
                        that.ywcdata = []
                        window.console.log(error)
                    })
            },
            shareshop () {
                var euphone = Encrypt(localStorage.getItem('uphone'))
                window.console.log('https://ecomm.sqnetsoft.cn/?uphone=' + euphone)
            },
            showdfk () {
                that.$router.push({ path: '/DFK' })
            },
            showdqr () {
                that.$router.push({ path: '/DQR' })
            },
            showdpj () {
                that.$router.push({ path: '/DPJ' })
            },
            showywc () {
                that.$router.push({ path: '/YWC' })
            },
            cardmana () {
                that.$router.push({ path: '/CardMana' })
            },
            clearhc () {
                localStorage.clear()
                clearAllCookie()
                that.$router.push({ path: '/' })
                Notify({ type: 'success', message: '清空完毕' });
            },
            reguserinfo: function () {
                var tmpdata = JSON.parse(localStorage.getItem('userinfo'))
                that.userimg = tmpdata.headimgurl
                that.usernick = tmpdata.nickname
                var adddata = {
                    openid: localStorage.getItem('openid'),
                    userimg: {String: that.userimg,Value:true},
                    usernick: {String: that.usernick,Value:true},
                    phone: {String: '',Value:true},
                    name: {String: '',Value:true}
                }
                window.console.log(adddata)
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/member/abuw',
                    method: 'post',
                    data: adddata,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.success) {
                            // 把相关数据缓存到本地
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            getuserinfo: function () {
                var qstr = {
                    whereinfo: " openid='" + localStorage.getItem('openid') + "'",
                    limit: '',
                    sortinfo: ''
                }
                that.$http({
                    url: 'https://ecommapi.sqnetsoft.cn/queryui',
                    method: 'post',
                    data: qstr,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(function (res) {
                        if (res.data.length > 0) {
                            var tmpdata = res.data[0]
                            if (tmpdata) {
                                that.userimg = tmpdata.imgurl
                                localStorage.setItem('userimg',tmpdata.imgurl)
                                that.usernick = tmpdata.nickname
                                localStorage.setItem('nickname',tmpdata.nickname)
                            } else {
                                localStorage.setItem('userinfo','')
                            }
                            window.console.log(res)
                            // 把相关数据缓存到本地
                        } else {
                            localStorage.setItem('userinfo','')
                        }
                    })
                    .catch(function (error) {
                        window.console.log(error)
                    })
            },
            makecode: function () {
                if (localStorage.getItem('ticket') === null || localStorage.getItem('ticket') === '') {
                    that.$http({
                        url: 'https://ecommapi.sqnetsoft.cn/wx/mc',
                        method: 'post',
                        data: {
                            accesstoken: getCookie('accesstoken'),
                            actionname: 'QR_LIMIT_STR_SCENE',
                            actioninfo: {
                                scene: {scene_str: localStorage.getItem('openid')}
                            },
                            color: '#000000'
                        },
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(function (res) {
                            if (res.data.success) {
                                // 把相关数据缓存到本地
                                var tmpdata = JSON.parse(res.data.data)
                                localStorage.setItem('ticket',tmpdata.ticket)
                                that.$router.push({ path: 'SharePage' })
                            }
                        })
                        .catch(function (error) {
                            window.console.log(error)
                        })
                } else {
                    that.$router.push({ path: 'SharePage' })
                }

            },
            contact () {
                that.$router.push({path: 'ContactUs'})
            }
        }
    }
    function getCookie (name) {
        var v = window.document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
        return v ? v[2] : null
    }
    function clearAllCookie() {
        var keys = document.cookie.match(/[^ =;]+(?==)/g)
        if (keys) {
            for (var i = keys.length; i--;) {
                document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString() // 清除当前域名下的,例如：m.ratingdog.cn
                document.cookie = keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString() // 清除当前域名下的，例如 .m.ratingdog.cn
                document.cookie = keys[i] + '=0;path=/;domain=ratingdog.cn;expires=' + new Date(0).toUTCString() // 清除一级域名下的或指定的，例如 .ratingdog.cn
            }
        }
    }
    function stopTouchendPropagationAfterScroll(){
        var locked = false;
        window.addEventListener('touchmove', function(ev){
            locked || (locked = true, window.addEventListener('touchend', stopTouchendPropagation, true));
            window.console.log(ev)
        }, true);
        function stopTouchendPropagation(ev){
            ev.stopPropagation();
            window.removeEventListener('touchend', stopTouchendPropagation, true);
            locked = false;
        }
    }
</script>
